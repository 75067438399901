<template>
  <div class="page-prox" id="Proximamente">
    <div class="grid-cont iguana">
      <div class="sec-1">
        <a href="/">
          <img src="../assets/logo_p.png" alt="logo" class="logo" />
          <p class="rose">THE WEB, DEV & MKT AGENCY</p>
        </a>
      </div>
      <div class="sec-2">
        <p class="purple">Digital</p>
        <p class="purple">transformat<br class="br1"/>ion</p>
        <p class="rose1 mo lf">is not an</p>
        <p class="rose1 mo">option</p>
        <p class="rose1 pc lf">is not </p>
        <p class="rose1 pc">an option</p>
      </div>
      <div class="sec-3">
        <p class="rose">coming soon.</p>
      </div>
      <div class="sec-4">
        <div class="sm-c">
          <a href="https://es-la.facebook.com/legrafica/"
          target="_blank" rel="noreferrer noopener">
            <img src="../assets/fb-i.svg" alt="sm" class="sm-i" />
          </a>
          <a href="https://www.instagram.com/legrafica/?hl=es"
          target="_blank" rel="noreferrer noopener">
            <img src="../assets/ig-i.svg" alt="sm" class="sm-i" />
          </a>
          <a href="https://twitter.com/legrafica"
          target="_blank" rel="noreferrer noopener">
            <img src="../assets/tw-i.svg" alt="sm" class="sm-i" />
          </a>
          <a href="https://mx.linkedin.com/company/legrafica"
          target="_blank" rel="noreferrer noopener">
            <img src="../assets/li-i.svg" alt="sm" class="sm-i" />
          </a>
        </div>
        <p><a href="mailto:info@legrafica.mx">info@legrafica.mx</a></p>
        <p><a href="tel:+526621715728">(662) 171 5728</a></p>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "Proximamente",

  data() {
    return {};
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Proximamente - Legrafica';
            }
        },
  }
};
</script>
<style scoped>
#app {
    height: 100%!important;
}
.rose {
  color: #ef67c2;
}
.rose1 {
  color: #ffb6ea;
}
.purple {
  color: #b2acfc;
}
/*@media (max-width: 768px) {
}*/
.page-prox {
  background-color: #e4e4f9;
}
div#Proximamente {
  height: 295.411vw;
  width: 100vw;
    overflow: hidden;
}
.grid-cont {
  width: 100%;
  height: 295.411vw;
  display: table;
}
.iguana {
  background-image: url("../assets/iguana-prox.png");
  background-size: 128.261vw;
  background-repeat: no-repeat;
  background-position-x: -15.459vw;
  background-position-y: 165.913vw;
}

/**sec-1 */
.sec-1 {
  padding: 10.628vw 14.734vw 19.807vw;
}
.logo {
  width: 47.826vw;
  height: auto;
}
.sec-1 p {
  font-family: "Gramatika-Medium";
  font-size: 1.691vw;
  line-height: 125.8%;
  letter-spacing: 0.085em;
  text-transform: uppercase;
  margin: 0;
  padding-top: 4.28vw;
}

/**sec-2 */
.sec-2 {
  width: 52.657vw;
  margin: auto;
}
.sec-2 p {
  font-family: "Gramatika-Medium";
  font-size: 6.039vw;
  line-height: 129.8%;
  margin: 0;
  position: relative;
}
.sec-2 p:nth-child(1) {
  left: 4.831vw;
}
.sec-2 .lf {
  left: 16.425vw;
}

/**sec-3 */
.sec-3 {
  width: 100%;
  height: 49.034vw;
  padding-top: 6.28vw;
  padding-bottom: 11.353vw;
}
.sec-3 p {
  font-family: "Gramatika-Medium";
  font-size: 31.401vw;
  line-height: 82.8%;
  margin: 0;
  position: relative;
  left: -3.623vw;
  top: -5vw;
}
/**sec-4 */
.sec-4 {
  width: 48.551vw;
  margin: auto;
}
.sm-c {
  width: 22.705vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 4.488vw;
}
.sm-i {
  width: 2.519vw;
  height: 2.519vw;
}
.sec-4 p {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 1.932vw;
  line-height: 198.3%;
  color: #000000;
  margin: 0;
}
.sec-4 a {
  color: #000000;
}
.br1{
  display: none;
}
.pc{
  display: none;
}
.mo{
  display: block;
}
@media (min-width: 768px) {
.br1{
  display: block;
}
.pc{
  display: block;
}
.mo{
  display: none;
}
  div#Proximamente{
    height: 53.854vw;
  }
  .grid-cont {
    display: flex;
    height: 53.854vw;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 63.802vw;
    margin: auto;
  }
  .iguana {
    background-size: 51.563vw;
    background-repeat: no-repeat;
    background-position-x: 2.135vw;
    background-position-y: bottom;
}
  /**sec1 */
  .sec-1 {
    order: 2;
    padding: 0;
    height: fit-content;
    padding-top: 1.458vw;
  }
  .logo {
    width: 12.448vw;
    height: auto;
  }
  .sec-1 p {
    font-family: "Gramatika-Medium";
    font-size: 0.365vw;
    padding-top: 2.083vw;
  }
  /**sec 2 */
  .sec-2 {
    order: 1;
    width: 9.635vw;
    height: fit-content;
    margin: 0;
    margin-top: 7.813vw;
    margin-left: 43.438vw;
    margin-bottom: 3.385vw;
  }
  .sec-2 p {
    font-size: 1.563vw;
    margin: 0;
  }
  .sec-2 p:nth-child(1) {
    left: 1.25vw;
  }
  .sec-2 .lf {
      left: 4.167vw;
  }
  /**sec 3 */
  .sec-3 {
    order: 3;
    width: 39.844vw;
    height: 17.083vw;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 11.51vw;
  }
  .sec-3 p {
    font-size: 10.417vw;
    left: 0;
    top: -1.35vw;
  }
  /**sec 4 */
  .sec-4 {
    order: 4;
    width: 10.469vw;
    margin: 0;
    margin-top: -1.458vw;
  }
  .sm-i {
      width: 0.536vw;
      height: 0.536vw;
  }
  .sm-c {
    width: 4.896vw;
    padding-bottom: 1.836vw;
  }
  .sec-4 p {
    font-size: 0.573vw;
    margin: 0;
  }
}
</style>
